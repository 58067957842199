
interface UserMetadata {
  storeName: string;
  email: string;
  phone: string;
}

interface VIPInfo {
  ip?: string;
  plan: string;
  planName?: string;  // 通过join查询得到
  expire_at?: string;
  invite_code?: string;
}

interface Task {
  id?: string;
  task_id: string;
  delivery_type: DeliveryType;
  address?: string;  // 上门取货地址
  uid: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  week_days: Array<number>; // 0: Sunday, 1: Monday, ... 只选中周几
  send_email: boolean;
  email: string;
  refresh_time: number;
  times: number;  // 任务已执行次数
  created_at?: Date;
  updated_at?: string;
  appointment_at?: string;  // 预约到的时间
  end_at?: string;
  status: string;
}

interface Plan {
  id: string;
  code: string;
  name: string;
  description: string;
  months: number;
  price: number;
  origin_price: number;
}

enum TaskStatus {
  Running = "running",
  Paused = "paused",
  Success = "success",
  Failed = "failed",
  Checking = "checking",  // 正在检查是否成功
}

enum DeliveryType {
  Provider = "provider",
  Particular = "particular",
  Home = "home",
}

interface Address {
  title: string;
  detail: string;
}

interface UserAddress {
  id?: string;
  task_id: string;
  addresses?: Array<Address>;
  status?: string;  // running, success
}

export type { Task, UserMetadata, Plan, VIPInfo, Address, UserAddress };
export { TaskStatus, DeliveryType };
