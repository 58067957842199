import { useEffect, useState } from 'react';
import { RadioGroup, Radio } from '@headlessui/react'
import { Snackbar, Modal, Button, CircularProgress } from '@mui/material';
import { supabase } from '../App';
import { DOCUMENT_URL } from '../const';
import { Plan, VIPInfo } from '../models.types';
import { getUser, getUserExtra, convertDate } from '../utils';

var plans: Plan[] = [
  { id: "plan1", code: "trial", name: "免费试用", description: "订阅1个月", months: 1, price: 0.01, origin_price: 100 },
]

export default function Recharge() {
  const [vipInfo, setVIPInfo] = useState<VIPInfo>({ plan: 'trial', expire_at: convertDate(new Date()) });
  const [QRUrl, setQRUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selected, setSelected] = useState<Plan>(plans[0]);
  const [loading, setLoading] = useState(false);

  const showMessage = (message: string) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }

  const onPay = async () => {
    if (!selected) return;
    setLoading(true);
    const { data, error } = await supabase.functions.invoke('/pay_order', {
      body: { id: selected.id }
    })
    setLoading(false);
    if (error) {
      showMessage(error.toString())
      return
    } else {
      console.log(data)
      setQRUrl(data.qr_url)
    }
    setOpen(false)
  }

  const initInfo = async () => {
    const { user, error } = await getUser();
    if (error || !user) return
    getUserExtra(user.id).then((data) => {
      if (data) setVIPInfo(data);
    })
    supabase.from('plans').select('*').gt('price', 0).order('price', { ascending: true }).then(({ data, error }) => {
      if (error) {
        showMessage(error.message)
        return
      } else if (data) {
        plans = data;
        setSelected(plans[0])
      }
    })
  }

  useEffect(() => {
    initInfo()
  }, [])

  return (
    <>
      {
        vipInfo.plan === 'free' ? (
          <div
            className='flex justify-start items-center rounded-lg shadow p-2 border cursor-pointer'
            onClick={() => setOpen(true)}
          >
            <img src='/imgs/icon-128.png' alt="logo" width={48} className='mr-4' />
            <div className=''>
              <h1 className='text-xl'>订阅会员</h1>
              <div className='text-gray-500'>无限使用，自动约仓</div>
            </div>
          </div>
        ) : (
          <div className='rounded-lg shadow p-2 border'>
            {
              vipInfo.plan === 'permant' ? <div>永久使用，不限时间</div> : <>
                <div className="flex items-center justify-between">
                  <div>
                  <div>订阅计划: {vipInfo.planName}</div>
                  {
                    vipInfo.expire_at && <>
                      <div className="mr-4">剩余天数: {Math.ceil((new Date(vipInfo.expire_at).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} 天</div>
                      <div className="mr-4">到期日期: {convertDate(vipInfo.expire_at)}</div>
                    </>
                  }
                  </div>
                  <Button variant="outlined" size='small' className='text-blue-500 border-blue-500 mr-2' onClick={() => setOpen(true)}>提前续费</Button>
                </div>
              </>
            }
          </div>
        )
      }

      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="bg-white rounded-lg p-4 m-2">
          <div className="flex justify-center items-center">
            <h2 className="text-center text-xl font-medium leading-6 text-gray-900">订阅会员</h2>
            <div className="absolute right-0 mr-6">
              <button onClick={() => setOpen(false)} className="text-gray-500 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 py-2">
            <RadioGroup value={selected} onChange={setSelected}>
              <div className="space-y-2">
                {plans.map((plan) => (
                  <Radio
                    key={plan.id}
                    value={plan}
                    className={({ checked }) =>
                      `'relative flex cursor-pointer rounded-lg px-4 py-2 shadow border'
                            ${checked ? 'bg-amber-200' : 'bg-white'}`
                    }
                  >
                    <div className="w-full">
                      <h3 className='text-lg'>{plan.name}</h3>
                      <div className='text-gray-500 text-xs'>{plan.description}</div>
                      <div>
                        <span className='text-2xl font-semibold'>
                          ￥{plan.price}
                        </span>{' '}
                        {plan.origin_price && (
                          <span className=" text-gray-500 line-through">￥{plan.origin_price}</span>
                        )}
                      </div>
                    </div>
                  </Radio>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="mt-1 text-center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              onClick={() => onPay()}
            >
              {loading ? <CircularProgress size={24}/> : <span>支付宝支付</span>}
            </Button>
            <Button
              type="button"
              fullWidth
              className="mt-1"
              onClick={() => setOpen(false)}
            >
              取消
            </Button>
            <div className="text-sm text-gray-500 mt-1" >
              <span>遇到问题？请立即 </span>
              <a
                href={DOCUMENT_URL}
                target="_blank"
                rel="noreferrer"
                className="font-semibold underline hover:underline transition underline-offset-2"
              >联系我们</a>
              <span> 处理</span>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={QRUrl !== ''}
        onClose={() => setQRUrl('')}
      >
        <div className="bg-white rounded-lg p-8 m-8 flex flex-col items-center">
          <img src={QRUrl} alt="支付宝二维码" />
          <div className="my-1">使用支付宝扫一扫付款</div>
          <div className="my-1 text-gray-500">支付成功后需重新打开页面</div>
        </div>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  )
}
