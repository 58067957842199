import { useEffect, useState } from 'react';
import { Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { getCurrentIP, getUser, getUserExtra, signOut } from '../utils';
import { UserMetadata } from '../models.types';
import { supabase } from '../App';

let uid = "";

const ActivationPage = ({ reload }: { reload: () => void }) => {
  const [userInfo, setUserInfo] = useState<UserMetadata>({ email: "", storeName: "", phone: "" });
  const [validIP, setValidIP] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validInviteCode, setValidInviteCode] = useState(true);
  const [inviteCode, setInviteCode] = useState("");
  const [ip, setIP] = useState("");
  const [trialDays, setTrialDays] = useState(7);

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    initInfo()
  }, [])

  const initInfo = async () => {
    let { user, error } = await getUser();
    if (error || !user) return
    uid = user.id || "";
    getCurrentIP().then((ip) => {
      setIP(ip || "");
    })
    const { data } = await supabase.from('plans').select('days').eq('code', 'trial').single();
    if (data) setTrialDays(data.days);
  }

  const activation = async () => {
    let anyerror = false;
    const ipRegex = /^((2((5[0-5])|([0-4]\d)))|([0-1]?\d{1,2}))(\.((2((5[0-5])|([0-4]\d)))|([0-1]?\d{1,2}))){3}$/;
    if (!ip || !ipRegex.test(ip)) {
      setValidIP(false);
      anyerror = true;
    }
    if (userInfo.phone && userInfo.phone.match(/^\d{11}$/) === null) {
      setValidPhone(false);
      anyerror = true;
    }
    if (inviteCode.length !== 6 || !/^[a-zA-Z]+$/.test(inviteCode)) {
      setValidInviteCode(false);
      anyerror = true;
    }
    if (anyerror) return

    setLoading(true);
    const { data, error } = await supabase.functions.invoke('activation', {
      body: { uid, ip, inviteCode }
    });
    if (error || (data && !data.success)) {
      setSnackbarMessage(data.message || error.message);
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }
    await supabase.auth.updateUser({ data: userInfo || undefined });
    await getUserExtra(uid, true);
    setLoading(false);

    reload();
  }

  return (
    <div className="space-y-2">
      <div className="text-red-500">限时特惠: 现在激活即赠送{trialDays}天会员</div>
      <div className="text-red-500">使用邀请码后，您和对方都将再获得7天会员</div>
      <div>
        <label className="block text-gray-700">店铺名称</label>
        <TextField
          type="text"
          size='small'
          fullWidth
          placeholder='可选'
          value={userInfo.storeName}
          onChange={(e) => setUserInfo({ ...userInfo, storeName: e.target.value })}
        />
      </div>
      <div>
        <label className="block text-gray-700">手机号</label>
        <TextField
          type="phone"
          size='small'
          fullWidth
          error={!validPhone}
          placeholder='可选，方便我们联系到您'
          value={userInfo.phone}
          helperText={!validPhone && "请输入11位手机号"}
          onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
        />
      </div>
      <div>
        <label className="block text-gray-700">店铺IP (需绑定IP, 绑定后只能用此IP地址使用插件)</label>
        <TextField
          type="text"
          size='small'
          fullWidth
          error={!validIP}
          helperText={!validIP && "请输入正确的IP, 例如: 112.123.115.123"}
          placeholder='绑定后将无法修改'
          value={ip}
          onChange={(e) => setIP(e.target.value)}
        />
      </div>
      <div>
        <label className="block text-gray-700">邀请码</label>
        <TextField
          type="text"
          size='small'
          fullWidth
          placeholder='6位字母邀请码'
          helperText={validInviteCode ? '没有邀请码? 点击右上角【联系我们】, 加入用户群找好心人~' : '邀请码应为6位字母'}
          error={!validInviteCode}
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
        />
      </div>
      <div className="mt-4"></div>
      <Button fullWidth variant="contained" onClick={activation} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : <span>免费激活店铺</span>}
      </Button>
      <Button className='mt-2' fullWidth variant="outlined" color="error" onClick={signOut}>退出登录</Button>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        action={(
          <Button
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            关闭
          </Button>
        )}
      />
    </div>
  )
}

export default ActivationPage;