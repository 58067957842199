import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button, Switch, TextField, RadioGroup, Radio,
  Snackbar, FormControlLabel, Checkbox, CircularProgress,
} from '@mui/material';
import { supabase } from '../App';
import { Task, TaskStatus, DeliveryType, VIPInfo, Address } from '../models.types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import { GET_URL } from '../const';
import { getCurrentIP, getUser, getUserExtra, daysOfWeek, getAddresses, deliveryTypeDisplay, generateInviteCode } from '../utils';
import SyncAddr from './SyncAddr';

const NOW = new Date();
const NEXT_MONTH = new Date(new Date().setMonth(NOW.getMonth() + 1));
let uid = "";
let vipInfo: VIPInfo;

const RunPage = () => {
  const [taskID, setTaskID] = useState<string>("");
  const [deliveryType, setDeliveryType] = useState(DeliveryType.Provider);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(NOW));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(NEXT_MONTH));
  const [startTime, setStartTime] = useState<Dayjs | null>(dayjs("2024-06-09T08:00"));
  const [endTime, setEndTime] = useState<Dayjs | null>(dayjs("2024-06-09T18:00"));
  // 选中周几
  const [weekDays, setWeekDays] = useState(["周一", "周二", "周三", "周四", "周五"]);

  const [sendEmail, setSendEmail] = useState(true);
  const [email, setEmail] = useState("");

  const [refreshTimeRadio, setRefreshTimeRadio] = useState(10);
  const [refreshTime, setRefreshTime] = useState(10);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // 上门取货的地址
  const [pickupAddress, setPickupAddress] = useState("");
  const [addresses, setAddresses] = useState<Address[]>([]);

  useEffect(() => {
    initInfo();
    loadFormData();
  }, []);

  useEffect(() => {
    saveFormData();
  }, [taskID, deliveryType, startDate, endDate, startTime, endTime, weekDays, sendEmail, email, refreshTime, pickupAddress]);

  const loadFormData = () => {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const formData = JSON.parse(savedData);
      setTaskID(formData.taskID || "");
      setDeliveryType(formData.deliveryType || DeliveryType.Provider);
      setStartDate(formData.startDate ? dayjs(formData.startDate) : dayjs(NOW));
      setEndDate(formData.endDate ? dayjs(formData.endDate) : dayjs(NEXT_MONTH));
      setStartTime(formData.startTime ? dayjs(formData.startTime) : dayjs("2024-06-09T08:00"));
      setEndTime(formData.endTime ? dayjs(formData.endTime) : dayjs("2024-06-09T18:00"));
      setWeekDays(formData.weekDays || ["周一", "周二", "周三", "周四", "周五"]);
      setSendEmail(formData.sendEmail || true);
      setEmail(formData.email || "");
      setRefreshTime(formData.refreshTime || 10);
      setRefreshTimeRadio(formData.refreshTimeRadio || 10);
      setPickupAddress(formData.pickupAddress || "");
    }
  };

  const saveFormData = () => {
    const formData = {
      taskID,
      deliveryType,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      startTime: startTime?.toISOString(),
      endTime: endTime?.toISOString(),
      weekDays,
      sendEmail,
      email,
      refreshTime,
      refreshTimeRadio,
      pickupAddress,
    };
    localStorage.setItem('formData', JSON.stringify(formData));
  };

  const initInfo = async () => {
    const { user, error: err } = await getUser();
    if (err) {
      console.error(err);
      return;
    }
    if (!user) return
    uid = user.id || "";
    setEmail(user.email || "");

    const data = await getUserExtra(uid, true);
    if (data) {
      await supabase.from('users').update({ last_use_time: new Date() }).eq('id', user.id);
    } else {
      let insertError;
      for (let attempts = 0; attempts < 10; attempts++) {
        const { error } = await supabase.from('users').insert({
          id: user.id,
          email: user.email,
          invite_code: generateInviteCode(uid),
          last_use_time: new Date(),
        });
        insertError = error;
        if (!insertError || insertError.code !== '23505') break;
      }
    }
    if (!data) return;
    vipInfo = data;
    if (data && data.expire_at && new Date(data.expire_at) < new Date()) {
      const { data, error } = await supabase.from('users').update({ plan: 'free' }).eq('id', user.id).select().single()
      if (error) {
        console.error(error)
      } else if (data) {
        vipInfo = data;
        if (chrome.storage) await chrome.storage.local.set({ vip: data });
      }
    }
  }

  const syncAddress = async () => {
    const addresses = await getAddresses(uid);
    if (addresses && addresses.addresses) {
      setAddresses(addresses.addresses);
      if (!pickupAddress) setPickupAddress(addresses.addresses[0].title);
    }
  }

  const handleRefreshTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value);
    if (value >= 10) setRefreshTime(value);
    if ([-1, 10, 30, 60].includes(value)) {
      setRefreshTimeRadio(value);
    }
  };

  const handleSubmitTask = () => {
    setLoading(true);
    const taskIDArray = taskID.split(/[,，]/).map(id => id.trim()).filter(id => id);
    Promise.all(taskIDArray.map(id => submitTask(id)))
      .then((results) => {
        setLoading(false);
        setSnackbarOpen(true);
        if (results.length === 1) {
          if (results[0]) {
            setSnackbarMessage("任务已更新");
          } else {
            setSnackbarMessage("任务已创建");
          }
        } else {
          setSnackbarMessage(`已提交 ${results.length} 个任务`);
        }
      })
      .catch((error) => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage((error as Error).message);
      });
  };

  const handleVIPInfo = async () => {
    return vipInfo.plan !== 'free';
  }

  const handleIP = async () => {
    const ip = await getCurrentIP();
    if (!vipInfo.ip) {
      throw new Error("请前往【我的信息】页绑定店铺IP后使用");
    } else if (vipInfo.ip !== ip) {
      throw new Error(`当前IP: ${ip} 与店铺绑定IP: ${vipInfo.ip} 不一致，无法使用。如需修改IP，请联系客服处理。`);
    }
  }

  const submitTask = async (taskID: string) => {
    if (!taskID || !/^\d{8}$/.test(taskID)) throw new Error("请输入8位数字的货件号");
    await handleIP();
    if (!await handleVIPInfo()) throw new Error("请前往【我的信息】页订阅会员后使用");
    const task: Task = {
      uid,
      task_id: taskID,
      delivery_type: deliveryType,
      address: pickupAddress,
      start_date: startDate?.format("YYYY-MM-DD") || "",
      end_date: endDate?.format("YYYY-MM-DD") || "",
      start_time: startTime?.format("HH:mm") || "",
      end_time: endTime?.format("HH:mm") || "",
      week_days: weekDays.map((day) => daysOfWeek.indexOf(day)),
      send_email: sendEmail,
      email: email,
      refresh_time: refreshTime,
      times: 0,
      status: TaskStatus.Running,
    }
    // 先保存数据库
    let isUpdate = false;
    let { data, error } = await supabase.from("tasks").insert(task).select().single();
    if (error) {
      if (error.code === '23505') {
        isUpdate = true;
        const { data: updateData, error: updateError } = await supabase.from("tasks").update(task)
          .eq("task_id", taskID).eq("uid", uid).eq("deleted", false).select().single();
        if (updateError) throw updateError;
        if (updateData) data = updateData;
      }
      else throw error
    }
    if (data && chrome.storage) await chrome.storage.local.set({ [taskID]: data });
    if (!isUpdate) {
      const url = GET_URL(taskID);
      if (chrome.tabs) await chrome.tabs.create({ url, active: false });
    }
    return isUpdate;
  }

  return (
    <div className="p-4 mt-2 bg-white space-y-1">
      <div>
        <label className="block text-gray-700">货件号</label>
        <TextField
          type="text"
          required
          fullWidth
          size="small"
          placeholder="请输入货件号, 例如27204690"
          helperText="支持一次输入多个货件号, 以逗号分隔"
          value={taskID}
          onChange={(e) => setTaskID(e.target.value)}
        />
      </div>

      <div>
        <label className="block text-gray-700">约货类型</label>
        <FormControl className='mt-2' size='small'>
          <Select
            value={deliveryType}
            className='min-w-[300px]'
            onChange={(e) => {
              setDeliveryType(e.target.value as DeliveryType);
              if (e.target.value === DeliveryType.Home) syncAddress();
            }}
          >
            {Object.entries(deliveryTypeDisplay).map(([key, value]) => (
              <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {
        deliveryType === DeliveryType.Home && (
          <>
            <div>
              <label className="block text-gray-700">取货地址</label>
              <FormControl className='mt-2' size='small'>
                <Select
                  value={pickupAddress}
                  className='min-w-[300px]'
                  onChange={(e) => setPickupAddress(e.target.value)}
                >
                  {addresses.map((address, i) => (
                    <MenuItem key={i} value={address.title} className='flex flex-col justify-start items-start'>
                      <div className='text-gray-700 text-sm'>{address.title.length > 40 ? address.title.substring(0, 40) + '...' : address.title}</div>
                      <div className='text-gray-500 text-xs'>{address.detail.length > 40 ? address.detail.substring(0, 40) + '...' : address.detail}</div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='mt-1'>
              <SyncAddr uid={uid} taskId={taskID} syncAddress={syncAddress} />
            </div>
          </>
        )
      }

      <div>
        <label className="block text-gray-700">日期范围 (墨西哥时间)</label>
        <div className="flex items-center mt-2">
          <DatePicker label="开始日期" value={startDate} onChange={setStartDate} />
          <span className="mt-2 mx-2">-</span>
          <DatePicker label="结束日期" value={endDate} onChange={setEndDate} />
        </div>
      </div>

      <div>
        <label className="block text-gray-700">时间范围 (墨西哥时间)</label>
        <div className="flex items-center mt-2">
          <TimePicker label="开始时间" ampm={false} views={["hours", "minutes"]} value={startTime} onChange={setStartTime} />
          <span className="mt-2 mx-2">-</span>
          <TimePicker label="结束时间" ampm={false} views={["hours", "minutes"]} value={endTime} onChange={setEndTime} />
        </div>
      </div>

      <div>
        <label className="block text-gray-700">选择周几</label>
        <FormControl className='mt-2' size='small'>
          <InputLabel>选择周几</InputLabel>
          <Select
            multiple
            value={weekDays}
            onChange={(e) => setWeekDays(e.target.value as string[])}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <div className="flex flex-wrap gap-2">
                {selected.map((i, value) => (
                  <Chip key={value} label={i} />
                ))}
              </div>
            )}
          >
            {daysOfWeek.map((day) => (
              <MenuItem
                key={day}
                value={day}
              >
                <Checkbox checked={weekDays.indexOf(day) > -1} />
                <ListItemText primary={day} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="flex items-center justify-between">
        <span className="text-gray-700">发送邮箱通知</span>
        <Switch checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} />
      </div>
      {
        sendEmail &&
        <div className="mb-1">
          <TextField
            type="email"
            value={email}
            size="small"
            label="通知邮箱"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      }

      <div>
        <label className="block text-gray-700">页面刷新间隔</label>
        <div>
          <RadioGroup row value={refreshTimeRadio} onChange={handleRefreshTimeChange}>
            <FormControlLabel value={10} control={<Radio />} label="10秒" />
            <FormControlLabel value={30} control={<Radio />} label="30秒" />
            <FormControlLabel value={60} control={<Radio />} label="60秒" />
            <FormControlLabel value={-1} control={<Radio />} label="自定义(秒)" />
            {refreshTimeRadio === -1 && (
              <TextField type="number"
                label="最小10秒"
                size="small"
                value={refreshTime} onChange={handleRefreshTimeChange}
              />
            )}
          </RadioGroup>
        </div>
      </div>
      <div className="flex justify-between">
        <Button variant="contained" fullWidth onClick={handleSubmitTask} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : <span>提交运行</span>}
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        action={(
          <Button
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            关闭
          </Button>
        )}
      />
    </div>
  )
}

export default RunPage;