import { useEffect, useState } from 'react';
import { supabase } from '../App'
import { IconButton, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Delete, PauseCircle, PlayCircle } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import { DeliveryType, Task, TaskStatus } from '../models.types';
import { GET_URL } from '../const';
import { getUser, getCachedTasks, convertDate, getWeekDay, daysOfWeek, deliveryTypeDisplay } from '../utils';

const TaskManager = () => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<Task[]>([]);

  const runTask = async (task: Task, tabs: chrome.tabs.Tab[] | null) => {
    task.status = task.status === TaskStatus.Running ? TaskStatus.Paused : TaskStatus.Running;
    if (chrome.storage) chrome.storage.local.set({ [task.task_id]: task });
    if (chrome.tabs && task.status === TaskStatus.Running) {
      const chrometabs = tabs || await chrome.tabs.query({});
      const isTaskInTabs = chrometabs.some(tab => tab.url?.includes(task.task_id));
      if (!isTaskInTabs) {
        chrome.tabs.create({ url: GET_URL(task.task_id), active: false });
      }
    }
    setTasks(tasks.map(t => t.id === task.id ? task : t));
  }

  const runAllPausedTasks = async () => {
    const pausedTasks = tasks.filter(task => task.status === TaskStatus.Paused);
    const tabs = chrome.tabs ? await chrome.tabs.query({}) : null;
    for (const task of pausedTasks) {
      await runTask(task, tabs);
    }
  }

  const deleteTask = async (task: Task) => {
    if (chrome.storage) chrome.storage.local.remove(task.task_id);
    if (chrome.tabs) {
      const tabs = await chrome.tabs.query({});
      for (const tab of tabs) {
        if (tab.url?.includes(task.task_id) && tab.id !== undefined) {
          chrome.tabs.remove(tab.id);
        }
      }
    }
    supabase.from('tasks').update({ deleted: true }).eq('id', task.id).then((data) => {
      console.info('任务已删除:', data);
    })
    setTasks(tasks.filter(t => t.id !== task.id));
  }

  const syncTaskStatus = async (tasks: Task[]) => {
    if (chrome.storage === undefined) return;
    const urls = tasks.map(task => task.task_id);
    // 同步本地task状态
    const result = await chrome.storage.local.get(urls);
    let updatedTasks = tasks.map(task => {
      const localTask = result[task.task_id];
      if (localTask) {
        return {
          ...task,
          status: localTask.status,
          times: localTask.times,
          updated_at: localTask.updated_at,
          end_at: localTask.end_at,
          appointment_at: localTask.appointment_at,
        };
      }
      return task;
    });
    // 同步tab状态
    updatedTasks = await syncTabStatus(updatedTasks);
    // 更新页面显示
    setTasks(updatedTasks);
    // 更新数据库
    const { error } = await supabase.from('tasks').upsert(updatedTasks, { onConflict: 'id' });
    if (error) {
      console.error('同步任务状态失败:', error);
    } else {
      console.info('任务状态已同步');
    }
    // 更新本地task
    await chrome.storage.local.set({'tasks': updatedTasks});
  };

  const syncTabStatus = async (tasks: Task[]) => {
    if (chrome.tabs === undefined) return [];
    const tabs = await chrome.tabs.query({});
    const tabUrls = tabs.map(tab => tab.url);
    const updatedTasks = tasks.map(task => {
      if (task.status === TaskStatus.Running) {
        const isTaskInTabs = tabUrls.some(url => url?.includes(task.task_id));
        if (!isTaskInTabs) {
          return {
            ...task,
            status: TaskStatus.Paused,
          };
        }
      }
      return task;
    });
    return updatedTasks;
  }

  const initInfo = async () => {
    setLoading(true);
    const { user, error } = await getUser();
    if (error || !user) return
    let uid = user.id;
    const localTasks = await getCachedTasks();
    if (localTasks.length > 0) {
      setTasks(localTasks);
      setLoading(false);
    }
    const { data } = await supabase.from('tasks').select().eq('uid', uid).eq('deleted', false);
    setTasks(data || []);
    syncTaskStatus(data || []);
    setLoading(false);
  }

  useEffect(() => {
    initInfo();
  }, []);

  return (
    <>
      {loading ? <div className="flex justify-center items-center h-screen">
        <CircularProgress color="secondary" size={64} />
      </div> :
        <div className="p-4 mt-2">
          {
            tasks.length === 0 ?
              <div className="min-h-80 flex items-center justify-center">
                <p className="text-gray-700 text-xl">没有任务</p>
              </div> :
              <div className="space-y-4 min-h-80">
                <Accordion className="shadow rounded-lg">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <p className='text-base'>如何修改任务</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>如需修改任务，在【新建任务】页面提交相同货件号的任务即可覆盖原任务</p>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="shadow rounded-lg mt-1" defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h2 className='text-base'>任务总览</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className='text-gray-700'>总任务数量: {tasks.length}</p>
                    <p className='text-gray-700'>已暂停任务: {tasks.filter(task => task.status === TaskStatus.Paused).length}</p>
                    <p className='text-gray-700'>运行中任务: {tasks.filter(task => task.status === TaskStatus.Running).length}</p>
                    <p className='text-gray-700'>成功的任务: {tasks.filter(task => task.status === TaskStatus.Success).length}</p>
                    <div className="flex justify-between">
                      <Button color="primary" variant='contained' fullWidth className='mt-2 mr-4' onClick={runAllPausedTasks}>启动已暂停任务</Button>
                      <Button color="primary" variant='outlined' fullWidth className='mt-2' onClick={initInfo}>刷新列表</Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {tasks.map(task => (
                  <div key={task.id} className="bg-white shadow rounded-lg p-4">
                    <div className='text-gray-700'>
                      <a href={GET_URL(task.task_id)} target="_blank" rel="noopener noreferrer"
                        className='text-xl font-semibold underline text-blue-400'>{task.task_id}</a>
                      {task.appointment_at && task.status === TaskStatus.Success && <div>
                        <p>预约时间(墨西哥时间): <span className='text-green-500 text-base'>{convertDate(task.appointment_at, 'America/Mexico_City')}</span></p>
                        <p>预约在: <span className='text-green-500 text-base'>{getWeekDay(task.appointment_at)}</span></p>
                      </div>}
                    </div>
                    <div className="text-gray-700 mt-2">
                      <p>约仓类型: {deliveryTypeDisplay[task.delivery_type]}</p>
                      {task.delivery_type === DeliveryType.Home && <p>取货地址: {task.address}</p>}
                      <p>日期范围: {task.start_date} - {task.end_date}</p>
                      <p>时间范围: {task.start_time} - {task.end_time}</p>
                      <p>周内范围: {task.week_days.map(day => daysOfWeek[day]).join(', ')}</p>
                      <p>运行间隔: {task.refresh_time} 秒</p>
                      <p>最近运行: {task.updated_at ? convertDate(task.updated_at) : "未运行"}</p>
                      <p>已运行次数: {task.times} 次</p>
                      {task.end_at && <p>结束时间: {convertDate(task.end_at)}</p>}
                      <p>当前状态: <span className={`${task.status === TaskStatus.Success ? 'text-green-500' :
                        task.status === TaskStatus.Failed ? 'text-red-500' :
                          task.status === TaskStatus.Running ? 'text-blue-500' :
                            'text-gray-500'}`}>
                        {task.status}</span>
                        {task.status === TaskStatus.Running && <CircularProgress size={16} color="secondary" className='ml-2' />}
                        {task.status === TaskStatus.Success && <CheckCircleIcon color="success" className='ml-2' />}
                        {task.status === TaskStatus.Failed && <ErrorIcon color="error" className='ml-2' />}
                      </p>
                    </div>
                    <div className='flex justify-end'>
                      <IconButton color="primary" onClick={() => runTask(task, null)}>
                        {task.status === TaskStatus.Running ? <PauseCircle /> : <PlayCircle />}
                      </IconButton>
                      <IconButton color="error" onClick={() => deleteTask(task)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
          }
        </div>
      }
    </>
  );
};

export default TaskManager;