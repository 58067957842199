import { Button, TextField, CircularProgress, Snackbar } from '@mui/material';
import { supabase } from '../App';
import { useEffect, useState } from 'react';
import { UserMetadata } from '../models.types';
import Recharge from './Recharge';
import { VIPInfo } from '../models.types';
import Activation from './Activation';
import { generateInviteCode, getUser, getUserExtra, signOut } from '../utils';

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [vipInfo, setVIPInfo] = useState<VIPInfo>({ plan: 'trial' });
  const [userInfo, setUserInfo] = useState<UserMetadata>({ email: "", storeName: "", phone: "" });
  const [validPhone, setValidPhone] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    initInfo();
  }, []);

  const initInfo = async () => {
    setLoading(true);
    const { user, error } = await getUser();
    if (error || !user) {
      setLoading(false);
      return
    }
    let userMetadata = {
      email: user.email || "",
      storeName: user.user_metadata.storeName || "",
      phone: user.user_metadata.phone || "",
    };
    setUserInfo(userMetadata);
    const userExtra = await getUserExtra(user.id);
    if (userExtra) {
      if (!userExtra.invite_code) {
        userExtra.invite_code = await initUserInviteCode(user.id);
        if (userExtra.invite_code !== '生成邀请码失败' && chrome.storage) {
          await chrome.storage.local.set({ vip: userExtra });
        }
      }
      setVIPInfo(userExtra);
    }
    setLoading(false);
  }

  const initUserInviteCode = async (uid: string): Promise<string> => {
    let inviteCode = "";
    for (let attempts = 0; attempts < 10; attempts++) {
      inviteCode = generateInviteCode(uid);
      const { error } = await supabase.from('users').update({ invite_code: inviteCode }).eq('id', uid);
      if (!error || error.code !== '23505') break;
    }
    if (!inviteCode) {
      console.error("生成邀请码失败");
      return '生成邀请码失败';
    }
    return inviteCode;
  }

  const updateUserInfo = () => {
    if (userInfo.phone && userInfo.phone.match(/^\d{11}$/) === null) {
      setValidPhone(false);
    }
    setValidPhone(true);
    supabase.auth.updateUser({ data: userInfo || undefined }).then(() => {
      getUser(true);
    });
  };

  return (
    <>
      {loading ? <div className="flex justify-center items-center h-screen">
        <CircularProgress color="secondary" size={64} />
      </div> :
        <div className="p-4 bg-white mt-2">
          {
            vipInfo.ip === null ? <Activation reload={initInfo} /> :
              <div className='space-y-2'>
                <div><Recharge /></div>
                <div>
                  <label className="block text-gray-700">邮箱</label>
                  <div className="mt-1 text-lg w-full border-b rounded-md">{userInfo.email}</div>
                </div>
                <div>
                  <label className="block text-gray-700">店铺IP</label>
                  <div className="mt-1 text-lg w-full border-b rounded-md">{vipInfo.ip}</div>
                </div>
                <div>
                  <label className="block text-gray-700">邀请码</label>
                  <div className="flex items-center space-x-2">
                    <div className="mt-1 text-lg w-full border-b rounded-md">{vipInfo.invite_code}</div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        const inviteInfo = `Hi, 我正在使用【有约】Mercado约仓助手，一键自动约仓，告别人工值守。\n现在使用我的邀请码 ${vipInfo.invite_code} 激活，我们都将获得7天免费会员。\n快去紫鸟浏览器插件市场搜索【有约】体验吧！`;
                        navigator.clipboard.writeText(inviteInfo);
                        setSnackbarMessage("邀请码已复制");
                        setSnackbarOpen(true);
                      }}
                    >
                      复制
                    </Button>
                  </div>
                  <p className="text-gray-500 mt-1">新用户使用邀请码激活后, 您和对方都将获得7天会员</p>
                </div>
                <div>
                  <label className="block text-gray-700">店铺名称</label>
                  <TextField
                    type="text"
                    size='small'
                    fullWidth
                    placeholder='可选'
                    value={userInfo.storeName}
                    onChange={(e) => setUserInfo({ ...userInfo, storeName: e.target.value })}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">手机号</label>
                  <TextField
                    type="phone"
                    size='small'
                    fullWidth
                    error={!validPhone}
                    placeholder='可选，方便我们联系到您'
                    value={userInfo.phone}
                    helperText={!validPhone && "请输入11位手机号"}
                    onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
                  />
                </div>
                <Button fullWidth variant="contained" onClick={updateUserInfo}>保存</Button>
                <div className="mt-2"></div>
                <Button fullWidth variant="outlined" color="error" onClick={signOut}>退出登录</Button>
                <Snackbar
                  open={snackbarOpen}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={3000}
                  message={snackbarMessage}
                  onClose={() => setSnackbarOpen(false)}
                  action={(
                    <Button
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => setSnackbarOpen(false)}
                    >
                      关闭
                    </Button>
                  )}
                />
              </div>
          }
        </div>
      }
    </>
  );
};

export default Profile;
