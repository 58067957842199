import React, { useState } from 'react';
import { Button, CircularProgress, Modal, TextField } from '@mui/material';
import { Address } from '../models.types';
import { GET_URL } from '../const';
import { updateAddresses } from '../utils';

const SyncAddr = ({ uid, taskId, syncAddress }: { uid: string, taskId: string, syncAddress: () => void }) => {
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [taskID, setTaskID] = useState(taskId);
  const [status, setStatus] = useState('');
  const [addrs, setAddrs] = useState([] as Array<Address>);

  const saveAddress = (addresses: Array<Address>) => {
    if (addresses.length > 0) {
      console.debug('地址保存成功');
      syncAddress();
      updateAddresses(uid, addresses);
    } else {
      console.debug('地址保存失败');
    }
  }

  const submitSync = async () => {
    await chrome.storage.local.set({
      'address': {
        'task_id': taskID,
        'status': 'running'
      }
    });
    const tab = await chrome.tabs.create({
      url: GET_URL(taskID),
      active: false
    });
    const intervalId = setInterval(async () => {
      const { address } = await chrome.storage.local.get(['address']);
      console.debug('检查同步地址状态', address);
      if (!address) return;
      if (address.status !== 'running') {
        clearInterval(intervalId);
        setStatus(address.status);
        if (address.status === 'success' && address.addresses && address.addresses.length > 0) {
          setAddrs(address.addresses);
          saveAddress(address.addresses);
          if (tab.id) chrome.tabs.remove(tab.id);
        }
      }
    }, 5000);
  }

  const handleSync = () => {
    if (!/^\d{8}$/.test(taskID)) {
      setHasError(true);
      return;
    }
    setStatus('running');
    if (!chrome.storage) {
      setTimeout(() => {
        setStatus('success');
      }, 5000);
    } else {
      submitSync();
    }
  }

  const renderStatus = () => {
    if (status === 'running') {
      return (
        <div className='flex justify-center items-center mt-4'>
          <CircularProgress />
          <p className='ml-2'>同步中...</p>
        </div>
      )
    } else if (status === 'success') {
      return (
        <div className="mt-2 bg-gray-200 p-2 rounded-lg">
          <p className='text-center'>同步到下拉列表完成，共 {addrs.length} 个地址</p>
          {addrs.map((addr) => (
            <div className='mt-2 border-b border-gray-300'>
              <p className='text-sm text-gray-700'>{addr.title}</p>
              <p className='text-xs text-gray-500'>{addr.detail}</p>
            </div>
          ))}
        </div>
      )
    } else if (status === 'failed') {
      return (
        <p className='text-center mt-2 text-red-500'>同步失败，请重试或联系客服</p>
      )
    } else {
      return (<></>)
    }
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>没有所需地址？点击同步地址</Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ overflow: 'auto' }}
      >
        <div className="bg-white rounded-lg p-4 m-2">
          <div>
            <div className="absolute right-0 mr-6">
              <button onClick={() => setOpen(false)} className="text-gray-500 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <h2 className='text-xl text-center'>同步地址</h2>
          </div>
          <div className='mt-2'>
            <p className='text-gray-700 text-base'>输入待约仓货件号，点击下方开始同步</p>
            <p className='mt-2 text-gray-500'>PS: 请选择没有在自动约仓的货件号，否则会与自动约仓冲突</p>
          </div>
          <TextField
            fullWidth
            margin="normal"
            label="请输入货件号，如23456789"
            variant="outlined"
            error={hasError}
            helperText={hasError ? '请输入8位数字的货件号' : ''}
            value={taskID}
            onChange={(e) => setTaskID(e.target.value)}
          />
          <Button variant="contained" fullWidth className='my-2' disabled={status === 'running'} onClick={handleSync}>
            开始同步
          </Button>
          {
            status === 'success' && (
              <Button fullWidth onClick={() => setOpen(false)}>关闭</Button>
            )
          }
          {renderStatus()}
        </div>
      </Modal>
    </div>
  );
}

export default SyncAddr;
