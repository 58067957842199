import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='flex flex-col items-center justify-center h-screen bg-gray-100'>
      <img src='/imgs/icon-128.png' alt="error" width={150} className='mb-4' />
      <h1 className='text-3xl font-bold mb-4'>重置链接不合法或已失效</h1>
      <p className='text-lg'>请注意，你的重置链接只能点击一次，之后就会失效。</p>
      <p className='text-lg'>请重新点击【忘记密码】按钮获取新邮件。</p>
      <p className='text-lg mb-4'>如果问题持续存在，请联系客服。</p>
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        返回
      </Button>
    </div>
  );
}

export default ErrorPage;
