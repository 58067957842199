import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../App';
import { LOCALIZATION } from '../const';
import { useEffect, useState } from 'react';

function ResetPass() {
  const [isUpdate, setIsUpdate] = useState(true);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'USER_UPDATED') {
        setIsUpdate(false)
      }
    })

    return () => subscription.unsubscribe()
  }, []);

  return (
    <div className='p-4 popup-container bg-white'>
      {
        isUpdate ?
          <Auth view='update_password' supabaseClient={supabase}
            localization={LOCALIZATION}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: '#2196f3',
                    brandAccent: '#1769aa',
                  },
                },
              },
            }}
            providers={[]}
          /> :
          <div className='flex flex-col items-center justify-center'>
            <h2 className='text-2xl font-bold'>密码重置成功，请前往插件登录</h2>
          </div>
      }
    </div>
  );
}

export default ResetPass;
