import { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TaskManager from './TaskManager';
import RunPage from './RunPage';
import Profile from './Profile';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import { Session } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { APP_NAME, LOCALIZATION } from '../const';
import { supabase } from '../App';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
  },
};

const theme = createTheme(themeOptions);

function App() {
  const navigate = useNavigate();

  const [session, setSession] = useState<Session | null>(null)
  const [activeTab, setActiveTab] = useState('runTab');
  const [title, setTitle] = useState(APP_NAME);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'runTab') setTitle(APP_NAME);
    else if (tab === 'taskTab') setTitle('任务管理');
    else if (tab === 'profileTab') setTitle('我的信息');
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      if (chrome.storage) chrome.storage.local.set({ session });
      supabase.auth.refreshSession();
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      if (chrome.storage) chrome.storage.local.set({ session });
      if (_event === 'PASSWORD_RECOVERY') {
        navigate('/reset-password');
      } else if (window.location.href.includes('error_code=403')) {
        navigate('/error');
      }
    })

    return () => subscription.unsubscribe()
  }, [navigate])

  if (!session) {
    return (
      <div className='p-4 popup-container bg-white'>
        <div className='flex items-center justify-center'>
          <img src='/imgs/icon-128.png' alt="logo" width={48} className='mr-4' />
          <h1 className='text-xl'>Mercado约仓助手</h1>
        </div>
        <Auth supabaseClient={supabase}
          // redirectTo={`http://localhost:3000`}  // 可以不用，以supabase中的SITE_URL为准
          localization={LOCALIZATION}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#2196f3',
                  brandAccent: '#1769aa',
                },
              },
            },
          }}
          providers={[]}
        />
      </div>)
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='popup-container pb-16'>
              <AppBar position='static' sx={{ background: '#FFE600', color: '#000' }}>
                <Toolbar className='flex justify-between'>
                  <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>{title}</Typography>
                  {
                    activeTab !== 'runTab' &&
                    <Button variant="outlined" color='inherit' className='ml-auto' onClick={
                      () => window.open('https://edreamai.feishu.cn/docx/HVJVdhirxoh7adxgjQnc1NShnMe', '_blank')
                    }>联系我们</Button>
                  }
                </Toolbar>
              </AppBar>

              {activeTab === 'runTab' && <RunPage />}
              {activeTab === 'taskTab' && <TaskManager />}
              {activeTab === 'profileTab' && <Profile />}

              <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1300 }} elevation={3}>
                <BottomNavigation
                  showLabels
                  value={activeTab}
                  onChange={(event, newValue) => {
                    handleTabClick(newValue);
                  }}
                >
                  <BottomNavigationAction label="新建任务" icon={<AddCircleIcon />} value="runTab" />
                  <BottomNavigationAction label="任务管理" icon={<AssignmentIcon />} value="taskTab" />
                  <BottomNavigationAction label="我的信息" icon={<AccountCircleIcon />} value="profileTab" />
                </BottomNavigation>
              </Paper>
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
