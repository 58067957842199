const APP_NAME = "Mercado约仓助手";
const SITE_URL = "https://yoyue.pages.dev";
const GET_URL = (taskID: string) => `https://myaccount.mercadolibre.com.mx/shipping/inbounds/${taskID}/appointment`;
const SUPABASE_URL = "https://bhmfzixilhdhqqgdhche.supabase.co";
const SUPABASE_ANNO_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJobWZ6aXhpbGhkaHFxZ2RoY2hlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTc5MjEyMTUsImV4cCI6MjAzMzQ5NzIxNX0.LJEXEo3pYtBMw3K6IaaR-5RCqYbjz3CEpf4EgxXsIX8";
const DOCUMENT_URL = "https://edreamai.feishu.cn/docx/HVJVdhirxoh7adxgjQnc1NShnMe";

const LOCALIZATION = {
  variables: {
    sign_up: {
      email_label: '邮箱',
      password_label: '密码',
      email_input_placeholder: '请输入邮箱',
      password_input_placeholder: '请输入密码',
      button_label: '注册',
      link_text: '没有账号? 注册',
      loading_button_label: '注册中...',
    },
    sign_in: {
      email_label: '邮箱',
      password_label: '密码',
      email_input_placeholder: '请输入邮箱',
      password_input_placeholder: '请输入密码',
      button_label: '登录',
      link_text: '已有账号? 登录',
      loading_button_label: '登录中...',
    },
    forgotten_password: {
      email_label: '邮箱',
      password_label: '密码',
      email_input_placeholder: '请输入邮箱',
      button_label: '发送重置邮件',
      link_text: '忘记密码? ',
      loading_button_label: '发送重置邮件中...',
      confirmation_text: '请检查您的邮箱，点击链接重置密码',
    },
    update_password: {
      password_label: '新密码',
      password_input_placeholder: '请输入新密码',
      button_label: '重置密码',
      loading_button_label: '重置中...',
      confirmation_text: '密码重置成功，请前往插件登录',
    }
  }
}

export { APP_NAME, SUPABASE_URL, SUPABASE_ANNO_KEY, DOCUMENT_URL, GET_URL, LOCALIZATION, SITE_URL };
