import { createClient } from '@supabase/supabase-js';
import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import ResetPass from './pages/ResetPass';
import { SUPABASE_URL, SUPABASE_ANNO_KEY } from './const';
import ErrorPage from './pages/Error';

const supabase = createClient(SUPABASE_URL, SUPABASE_ANNO_KEY);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
export { supabase };
