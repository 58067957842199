import { supabase } from './App';
import { VIPInfo, Task, UserAddress, Address, DeliveryType } from './models.types';

const daysOfWeek = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
const deliveryTypeDisplay = {
  [DeliveryType.Particular]: '私家车送货',
  [DeliveryType.Provider]: '供应商送货',
  [DeliveryType.Home]: '上门取货',
}

/**
 * 将日期转换为指定时区
 * @param date
 * @param timeZone 时区，默认上海时区
 * @returns {date}
 */
const convertDate = (date: string | Date, timeZone = 'Asia/Shanghai'): string => {
  return new Date(date).toLocaleString('zh-CN', { timeZone })
}

/**
 * 获取日期是星期几
 * @param date
 * @returns {number}
 */
const getWeekDay = (date: string | Date): string => {
  return daysOfWeek[new Date(date).getDay()];
}

/**
 * 使用本地缓存加速获取IP
 * @returns {ip}
 */
const getCurrentIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (response.ok) {
      const data = await response.json();
      const ip = data.ip;
      // 永久存储IP
      localStorage.setItem('cachedIP', ip);
      return ip;
    } else {
      throw new Error(`获取IP地址失败 ${await response.text()}`);
    }
  } catch (error) {
    throw new Error(`获取IP地址失败 ${error}`);
  }
};

/**
 * 使用本地缓存加速获取用户
 * @returns {user, error}
 */
const getUser = async (nocache = false) => {
  let user = null;
  let error = null;
  if (chrome.storage) {
    const { session } = await chrome.storage.local.get(['session']);
    if (session && session.user) user = session.user;
  }
  if (!user || nocache) {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (chrome.storage) chrome.storage.local.set({ session });
    user = session?.user
  }
  return { user, error };
};

/**
 * 使用本地缓存加速获取用户的额外信息
 * @returns {user, error}
 */
const getUserExtra = async (uid: string, nocache = false) => {
  let userData: VIPInfo | null = null;
  if (chrome.storage) {
    const { vip } = await chrome.storage.local.get(['vip']);
    if (vip && vip.id === uid) {
      userData = vip;
    }
  }
  if (!userData || nocache) {
    console.debug('用户数据不存在，尝试从数据库中获取');
    const { data, error } = await supabase.from('users').select('id,plan,expire_at,ip,invite_code,plans(name)').eq('id', uid).single();
    if (error) {
      console.error('获取用户数据失败:', error);
      return null;
    }
    userData = data;
    let planInfo = data.plans as any;
    userData.planName = planInfo.name;
    if (chrome.storage) await chrome.storage.local.set({ vip: userData });
  }
  return userData;
}


/**
 * 使用本地缓存加速获取已缓存的任务
 * 仅用于前端缓存，与本地任务执行无关
 * @returns {tasks}
 */
const getCachedTasks = async () => {
  if (chrome.storage) {
    const result = await chrome.storage.local.get(['tasks']);
    const tasks: Task[] = result.tasks || [];
    return tasks;
  }
  return [];
}

/**
 * 获取用户的地址
 * @param uid 用户ID
 * @returns {addresses}
 */
const getAddresses = async (uid: string) => {
  if (chrome.storage) {
    const { address } = await chrome.storage.local.get(['address']);
    if (address) {
      return address as UserAddress;
    }
  }
  const { data, error } = await supabase.from('users').select('id,addresses').eq('id', uid).single();
  if (error) {
    console.error('获取地址失败:', error);
    return null;
  }
  return data as UserAddress;
}

/**
 * 更新地址
 * @param uid 用户ID
 * @param addresses 地址
 * @returns {error}
 */
const updateAddresses = async (uid: string, addresses: Array<Address>) => {
  const { error } = await supabase.from('users').update({ addresses }).eq('id', uid);
  if (error) {
    console.debug('更新地址失败:', error);
  }
}

/**
 * 退出登录
 * @returns {error}
 */
const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.debug('退出登录失败:', error);
  } else if (chrome.storage) {
    await chrome.storage.local.remove(['session', 'vip', 'tasks', 'address']);
  }
}

/**
 * 生成邀请码
 * @param uid 用户ID
 * @returns {code}
 */
const generateInviteCode = (uid: string) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let inviteCode = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    inviteCode += characters[randomIndex];
  }
  return inviteCode.toUpperCase();
};

export { 
  convertDate, getCurrentIP, getUser, getUserExtra, 
  getCachedTasks, getWeekDay, daysOfWeek, getAddresses,
  updateAddresses, deliveryTypeDisplay, signOut,
  generateInviteCode
};
